import React from 'react';
import styled from 'styled-components';
import Typist from 'react-typist';
import 'react-typist/dist/Typist.css';

import Section from '../components/section';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { media } from '../utils/media';

const Wrapper = styled(Section)`
  max-width: 800px;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  font-size: 14px;
  letter-spacing: 1px;
  padding-top: 5rem !important;
  ${media.tablet`
    padding: 0px 20px;
    padding-top: 5rem !important;
  `};
  ${media.thone`
    padding: 0px 20px;
    padding-top: 5rem !important;
  `};
  ul {
    margin-bottom: 40px;
  }
  a{
    color: #6dc9c8;
    cursor: pointer;
  }
  a:hover{
    color: #6dc9c8;
    text-decoration: none;
  }
`;


const Text = styled.h1`
  color: #6dc9c8;
  text-transform: uppercase;
  font-size: 25px;
  letter-spacing: 5px;
  line-height: 30px;
  text-align: center;
  ${media.tablet`
    font-size: 23px;
  `};
`;

const More = styled.div`
  width: 100%;
  position: relative;
  margin: 50px 0px;
  text-align: center;
  a {
    color: #3C3C3E;
    z-index: 21;
    font-size: 13px;
    letter-spacing: 4px;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
    left: calc(50% + 3px);
    width: 100%;
    transform: translate(-50%, 0%);
    transition: all -0.3s;
    div {
      position: absolute;
      z-index: -1;
      left: 50%;
      transform: translate(-50%, 0%);
      top: 15px;
      width: 200px;
      height: 20px;
      background-color: #6dc9c8;
    }
  }
`;

const Contact = () => (
  <Layout>
    <SEO title='Contact' />
    <Wrapper>
    <h1>Stay in touch</h1> <br/><br/>
    <p>You can find me around the web:</p>
      <br/>
    <ul>
          <li>
            <strong>Email</strong>: <a href="mailto:hi@et.mk">hi[-at-]et.mk</a>
          </li>
          <li>
            <strong>Telegram</strong>:{' '}
            <a target="_blank" href="https://t.me/g3tux" rel="noreferrer">
            g3tux
            </a>
          </li>
        
          
          <li>
            <strong>LinkedIn</strong>:{' '}
            <a target="_blank" href="https://linkedin.com/in/edytk" rel="noreferrer">
            edytk
            </a>
          </li>
          <li>
            <strong>GitHub</strong>:{' '}
            <a target="_blank" href="https://github.com/edytk" rel="noreferrer">
            edytk
            </a>
          </li>
          <li>
            <strong>GitLab</strong>:{' '}
            <a target="_blank" href="https://gitlab.com/etk" rel="noreferrer">
            etk
            </a>
          </li>
          <li>
            <strong>Twitter</strong>:{' '}
            <a target="_blank" href="https://twitter.com/_tvmv" rel="noreferrer"> 
            _tvmv
            </a>
          </li>

          If you are a security conscious person, here is my <a href="https://keybase.io/edytk/key.asc">public key</a>. Dont be a stranger, just say hello!
         
        </ul>

      {/* <More>
        <a href='mailto:hi@et.mk'>
          Send an email
          <div />
        </a>
      </More> */}
{/* 
      <Text>
        <Typist avgTypingDelay={40}>Dont be a stranger, just say hello! 👋</Typist>
      </Text> */}

      
    </Wrapper>
  </Layout>
);

export default Contact;
